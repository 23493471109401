<template>
  <div class="grid grid-spacing" :class="image ? 'lg:grid-cols-2' : ''">
    <div
      :class="
        image && ['right', 'textright'].includes(variation)
          ? 'lg:col-start-2 lg:row-start-1 lg:order-2'
          : ''
      "
    >
      <component
        :is="heading.component"
        v-if="title || $slots.title"
        :class="[heading.classes, 'leading-relaxed']"
      >
        <span
          v-if="subtitle"
          class="block text-base font-bold text-brand-grey-600"
          >{{ subtitle }}</span
        >
        <span :class="!image ? titleBorder : ''"
          ><slot name="title">{{ title }}</slot></span
        >
      </component>
      <!-- NB: lead is supposed to be plain text -->
      <div
        v-if="lead || $slots.lead"
        class="content-area-lead sm:text-base my-4 md:mt-6 xl:mt-8"
      >
        <slot name="lead">{{ lead }}</slot>
      </div>
      <slot>
        <div v-if="body" class="content-copy" :class="color">
          <div class="copy" v-html="$md.render(body)"></div>
        </div>
      </slot>
      <template v-if="validCtaList.length > 0 || $slots.cta">
        <slot name="cta">
          <div class="flex gap-4 mt-8">
            <A11yButton
              v-for="(c, i) in validCtaList"
              :key="i"
              :color="getCtaColour(c)"
              :component="c.component"
              :href="c.href"
              :linktype="c.linktype"
              class="w-full gap-2 sm:w-auto"
            >
              <span>{{ c.text }}</span>
              <icon class="text-2xl" :name="getCtaIcon(c)"></icon>
            </A11yButton>
          </div>
        </slot>
      </template>
    </div>
    <div
      v-if="image"
      :class="
        ['right', 'textright'].includes(variation)
          ? 'lg:col-start-1 lg:row-start-1'
          : ''
      "
    >
      <LazyImg
        src="/images/lz.png"
        class="w-full object-cover"
        :class="responsiveImage ? 'h-full' : ''"
        :data-src="getImgSrc(image.src)"
        :alt="image.alt"
      />
    </div>
  </div>
</template>

<script>
import useHelpers from '~/composables/useHelpers'

export default {
  name: 'ContentArea',
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    headingComponent: {
      type: String,
      default: 'h2'
    },
    lead: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'grey'
    },
    image: {
      type: Object,
      default: () => {}
    },
    variation: {
      type: String,
      default: '' // right = text right : text left - requires image
    },
    body: {
      type: String,
      default: ''
    },
    // stretches the image vertically
    responsiveImage: {
      type: Boolean,
      default: false
    },
    cta: {
      type: [Object, Array],
      default: () => {}
    }
  },
  setup() {
    const { getImgSrc } = useHelpers()
    return {
      getImgSrc
    }
  },
  computed: {
    heading() {
      return {
        component: this.image ? 'h3' : this.headingComponent || 'h2',
        classes: this.image ? 'h3' : 'h2'
      }
    },
    titleBorder() {
      if (this.color === 'grey')
        return `border-b-5 md:border-b-5 border-brand-grey-200`
      else return `border-b-5 md:border-b-5 border-brand-${this.color}-300`
    },
    validCtaList() {
      if (this.cta && Object.keys(this.cta).length) {
        const ctaList = Array.isArray(this.cta) ? this.cta : [this.cta]
        return ctaList.filter((item) => !!item.text && !!item.href)
      }
      return []
    }
  },
  methods: {
    getCtaIcon(cta) {
      return cta?.icon?.name || cta?.icon || 'IconArrowRight'
    },
    getCtaColour(cta) {
      return cta?.color ? `${cta.color}-500` : 'blue-500'
    }
  }
}
</script>
